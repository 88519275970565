import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ChangeEvent } from "react";
import { DataState, ModalState } from "../../../interfaces/enums";
import {
	ModalTitleBar, ModalContent, ModalButtons, ModalHeader, ModalTextEntry, ModalContainer,
	ModalBlueButton, ForgotPassword, RemberMeContainer
} from "../ModalStyles";
import { setModalContent } from "../modalSlice";
import {
	setUsername, setPassword, selectPassword, selectUsername, validateUser, selectRememberMe, setRemeberMe,
	selectValidateUserStatus, selectUserError, selectUserErrorCode
} from "../../UserSettingsPages/SettingsState/userSlice";
import { CheckBox } from "../../Global/GobalStyledComponents";
import { SettingsLabel } from "../../UserSettingsPages/components/SettingsStyles";
import Loading from "../../Global/Loading";
import CloseButtonNewUser from "./CloseButtonNewUser";
import { StyledErrorMessage } from '../../Global/GobalStyledComponents';

const ModalLogin = () => {

	const dispatch = useAppDispatch();
	const loginName = useAppSelector(selectUsername);
	const loginPassword = useAppSelector(selectPassword);
	const rememberMe = useAppSelector(selectRememberMe);
	const validateStatus = useAppSelector(selectValidateUserStatus);
	const errorMessage = useAppSelector(selectUserError);
	const errorCode = useAppSelector(selectUserErrorCode);

	const handleLoginNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setUsername(e.target.value));
	}

	const handleLoginPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch(setPassword(e.target.value));
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			login()
		}
	}

	const login = () => {
		dispatch(validateUser({ loginName: loginName, password: loginPassword }))
	}

	return (
		<ModalContainer>
			<ModalTitleBar>
				<div></div>
				<CloseButtonNewUser />
			</ModalTitleBar>
			<ModalContent>
				<ModalHeader>Login To Your Account</ModalHeader>
				{validateStatus !== DataState.LOADING ?
					<>
						<ModalTextEntry placeholder='Username' value={loginName} onChange={(e) => handleLoginNameChange(e)} onKeyDown={((e) => handleKeyDown(e))} />
						<ModalTextEntry type='password' placeholder='Password' value={loginPassword} onChange={(e) => handleLoginPasswordChange(e)} onKeyDown={((e) => handleKeyDown(e))} />
						<RemberMeContainer>
							<CheckBox id='rememberMe' defaultChecked={rememberMe} onChange={() => { dispatch(setRemeberMe()) }} />
							<SettingsLabel htmlFor='rememberMe'>Remember Me</SettingsLabel>
						</RemberMeContainer>
						<ForgotPassword onClick={() => dispatch(setModalContent(ModalState.LOGINNAMERECOVER))}>Retrieve My Username</ForgotPassword>
						<ForgotPassword onClick={() => dispatch(setModalContent(ModalState.FORGOTPASSWORD))}>Retrieve My Password</ForgotPassword>

						<ModalButtons>
							<ModalBlueButton onClick={() => login()}>Login</ModalBlueButton>
							{/* <ModalWhiteButton onClick={() => dispatch(setModalContent(ModalState.SIGNUP))}>Signup</ModalWhiteButton> */}
						</ModalButtons>
					</>
					: <Loading />}
				{validateStatus === DataState.FAILED ?
					<>
						{errorCode === 1004 ? <StyledErrorMessage>Login Failed Please Check Login Name and Password and Try again.</StyledErrorMessage> : ''}
						{errorCode === 1005 || errorCode === 1017 ? <StyledErrorMessage>User Name Not Located. Re-enter Account Credentials and Try Again.</StyledErrorMessage> : ''}
						{errorCode === 1019 ? <StyledErrorMessage>Can Migrate account: {errorMessage}</StyledErrorMessage> : ''}
						{errorCode === 1020 ? <StyledErrorMessage>Can Migrate account: {errorMessage}</StyledErrorMessage> : ''}
						{errorCode === 1022 ? <StyledErrorMessage>Account is Locked Due to Excessive Failed Login Attempts. Use the Contact Us Link to Direct our Support Staff to Unlock Your Account.</StyledErrorMessage> : ''}
						{errorCode === 1024 ? <StyledErrorMessage>Login Failed - Password Not Valid. Re-enter Account Credentials and Try Again.</StyledErrorMessage> : ''}
						{errorCode !== 0 && errorCode !== 1004 && errorCode !== 1005 && errorCode !== 1017 && errorCode !== 1019 && errorCode !== 1020 && errorCode !== 1022 && errorCode !== 1024 ? <StyledErrorMessage>{errorMessage}</StyledErrorMessage> : ''}
					</>
					: ''
				}
			</ModalContent>
		</ModalContainer>
	)
}

export default ModalLogin