import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getBaseUrl } from "../Global/GobalFunctions";
import { Message, Messages, PostContactUs } from "../../interfaces/settings";
import { DataState } from "../../interfaces/enums";
import { errorLog } from "../Global/ConsoleLogging";

const baseUrl = getBaseUrl();

export interface otherLegalState {
	contactName: string;
	contactEmail: string;
	contactReason: string;
	contactZip: string;
	contactTVProvider: string;
	contactMessage: string;
	contactUsStatus: DataState;
	errorStatus: {
		errorMessage: string;
		errorCode: number;
	};
	messageStatus: DataState;
	messages: Messages;
	isServerMessage: boolean;
	regMessages: Message[];
	guestMessages: Message[];
}

const initialState: otherLegalState = {
	contactName: '',
	contactEmail: '',
	contactReason: '',
	contactZip: '',
	contactTVProvider: '',
	contactMessage: '',
	contactUsStatus: DataState.IDLE,
	errorStatus: {
		errorMessage: '',
		errorCode: 0
	},
	messageStatus: DataState.IDLE,
	messages: {} as Messages,
	isServerMessage: false,
	regMessages: [],
	guestMessages: []
};

const sortMessages = (state: any, messages: Messages) => {
	let guest: Message[] = []
	let reg: Message[] = []
	for (let i = 0; i < messages.messages.length; i++) {
		const m: Message = messages.messages[i];
		if (m.displayToGuests) {
			guest.push(m);
		}
		if (m.displayToMembers) {
			reg.push(m)
		}
	}
	state.guestMessages = guest;
	state.regMessages = reg;
}

export const contactUs = createAsyncThunk('otherLegal/contactUs', async (data: PostContactUs) => {
	const url = `${baseUrl}support/${data.userId}`;
	const req = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data.contactUs),
	}
	return fetch(url, req)
		// .then(res => res.json())
		// .then(data => { return data })
		.catch((err) => errorLog(`Contact Us Error:: ${err}`, true))
});

export const fetchMessage = createAsyncThunk('otherLegal/fetchMessage', async () => {
	return fetch(baseUrl + 'message')
		.then(res => res.json())
		.then(data => { return data })
		.catch((err) => errorLog(`Fetch message Error: ${err}`, true))
})

export const otherLegalStateSlice = createSlice({
	name: 'otherLegal',
	initialState,
	reducers: {
		setContactName: (state, action) => {
			state.contactName = action.payload
		},
		setContactEmail: (state, action) => {
			state.contactEmail = action.payload
		},
		setContactReason: (state, action) => {
			state.contactReason = action.payload
		},
		setContactZip: (state, action) => {
			state.contactZip = action.payload
		},
		setContactTVProvider: (state, action) => {
			state.contactTVProvider = action.payload
		},
		setContactMessage: (state, action) => {
			state.contactMessage = action.payload
		},
		setContactStatus: (state, action) => {
			state.contactUsStatus = action.payload;
		},
		setMessageStatus: (state, action) => {
			state.messageStatus = action.payload;
		},
		setIsServerMessage: (state, action) => {
			state.isServerMessage = action.payload;
		}
	},

	extraReducers: builder => {

		// #region contactUs
		builder.addCase(contactUs.pending, (state) => {
			state.contactUsStatus = DataState.LOADING
		})
		builder.addCase(contactUs.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.contactUsStatus = DataState.FAILED
			} else {
				state.contactUsStatus = DataState.SUCCEEDED
			}
		})
		builder.addCase(contactUs.rejected, (state, action) => {
			state.contactUsStatus = DataState.FAILED
			const errors: any = action.payload;
			state.errorStatus.errorMessage = errors.errorMessage;
			state.errorStatus.errorCode = errors.errorCode;
		})
		//#endregion

		//#region fetchMessage
		builder.addCase(fetchMessage.pending, (state) => {
			state.messageStatus = DataState.LOADING
		})
		builder.addCase(fetchMessage.fulfilled, (state, action) => {
			if (action.payload === undefined) {
				state.messageStatus = DataState.FAILED
			} else {
				state.messages = action.payload
				if (state.messages.messages.length > 0) {
					state.isServerMessage = true;
					sortMessages(state, action.payload);
				}
			}
			state.messageStatus = DataState.SUCCEEDED
		})
		builder.addCase(fetchMessage.rejected, (state, action) => {
			state.messageStatus = DataState.FAILED
		})
		//#endregion

	}
});

export const {
	setContactName, setContactEmail, setContactReason, setContactZip, setContactTVProvider, setContactMessage, setContactStatus,
	setMessageStatus, setIsServerMessage
} = otherLegalStateSlice.actions;

export const selectContactName = (state: RootState) => state.otherLegal.contactName;
export const selectContactEmail = (state: RootState) => state.otherLegal.contactEmail;
export const selectContactReason = (state: RootState) => state.otherLegal.contactReason;
export const selectZip = (state: RootState) => state.otherLegal.contactZip;
export const selectTVProvider = (state: RootState) => state.otherLegal.contactTVProvider;
export const selectMessage = (state: RootState) => state.otherLegal.contactMessage;
export const selectContactUsStatus = (state: RootState) => state.otherLegal.contactUsStatus
export const selectMessageStatus = (state: RootState) => state.otherLegal.messageStatus
export const selectMessages = (state: RootState) => state.otherLegal.messages;
export const selectIsServerMessage = (state: RootState) => state.otherLegal.isServerMessage;
export const selectRegMessages = (state: RootState) => state.otherLegal.regMessages;
export const selectGuestMessages = (state: RootState) => state.otherLegal.guestMessages;

export default otherLegalStateSlice.reducer;